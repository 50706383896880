/**
 * @generated SignedSource<<8b0804b274eac34c293d41ba81f097cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_allStudyPermissions$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteCaptures" | "StudyPermissions_canDeleteStudy" | "StudyPermissions_canEditStudy" | "StudyPermissions_canEditStudyAuthor" | "StudyPermissions_canFinalizeStudy" | "StudyPermissions_canGenerateStudyPdf" | "StudyPermissions_canManageEndpoints" | "StudyPermissions_canMarkAsDraft" | "StudyPermissions_canMoveStudy" | "StudyPermissions_canPermanentlyDeleteStudy" | "StudyPermissions_canSaveMeasurements" | "StudyPermissions_canSplitStudies" | "StudyPermissions_canTagStudy" | "StudyPermissions_canUndeleteStudy" | "StudyPermissions_studyOrArchiveIsDeleted" | "useStudyFinalization_study">;
  readonly " $fragmentType": "StudyPermissions_allStudyPermissions";
};
export type StudyPermissions_allStudyPermissions$key = {
  readonly " $data"?: StudyPermissions_allStudyPermissions$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_allStudyPermissions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Archive",
    "kind": "LinkedField",
    "name": "archive",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineDataFragmentSpread",
  "name": "StudyPermissions_studyOrArchiveIsDeleted",
  "selections": (v2/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = [
  (v4/*: any*/),
  (v3/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanFinalize",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  (v7/*: any*/)
],
v11 = [
  (v9/*: any*/)
],
v12 = [
  (v7/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyPermissions_allStudyPermissions",
  "selections": [
    (v3/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canDeleteStudy",
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanDelete",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canUndeleteStudy",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canPermanentlyDeleteStudy",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canMoveStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanMove",
          "storageKey": null
        },
        (v4/*: any*/),
        (v3/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canManageEndpoints",
      "selections": (v5/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canMarkAsDraft",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanMarkAsDraft",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canEditStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanEdit",
          "storageKey": null
        },
        (v4/*: any*/),
        (v3/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canSplitStudies",
      "selections": (v6/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canDeleteCaptures",
      "selections": (v6/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canSaveMeasurements",
      "selections": [
        (v4/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canGenerateStudyPdf",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "practiceType",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canFinalizeStudy",
      "selections": (v6/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canTagStudy",
      "selections": (v5/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canEditStudyAuthor",
      "selections": (v6/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useStudyFinalization_study",
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizationRequiresAttestation",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPendingFinalization",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAssociatedWorklistItem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasBeenFinalized",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "transcribedBy",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worksheet",
          "kind": "LinkedField",
          "name": "worksheets",
          "plural": true,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examTypes",
          "plural": true,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attestationMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "worklistRequiredToFinalize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "patientIdRequiredToFinalize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "worksheetRequiredToFinalize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "examTypeRequiredToFinalize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "studyAuthorRequiredToRequestFinalization",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EhrArchiveAssociation",
              "kind": "LinkedField",
              "name": "ehrAssociations",
              "plural": true,
              "selections": (v11/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Patient",
          "kind": "LinkedField",
          "name": "patient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medicalRecordNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "latestStudyEvent",
          "plural": false,
          "selections": [
            {
              "alias": "type",
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v12/*: any*/),
              "type": "Node",
              "abstractKey": "__isNode"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyFilters_study",
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useRefreshStudyDialog_study",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "studyAuthors_study",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "secondaryAuthors",
              "plural": true,
              "selections": (v12/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "1f90d381d9215afae43108225cd2cc10";

export default node;
