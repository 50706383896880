/**
 * @generated SignedSource<<55f46eb37bf47defbb66e4cd2532bb7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useStudyFinalization_study$data = {
  readonly archive: {
    readonly attestationMessage: string | null;
    readonly ehrAssociations: ReadonlyArray<{
      readonly __typename: "EhrArchiveAssociation";
    } | null> | null;
    readonly examTypeRequiredToFinalize: boolean | null;
    readonly patientIdRequiredToFinalize: boolean | null;
    readonly studyAuthorRequiredToRequestFinalization: boolean | null;
    readonly worklistRequiredToFinalize: boolean | null;
    readonly worksheetRequiredToFinalize: boolean | null;
  } | null;
  readonly createdBy: {
    readonly __typename: "UserProfile";
    readonly id: string;
  } | null;
  readonly examTypes: ReadonlyArray<{
    readonly __typename: "ExamType";
  } | null> | null;
  readonly finalizationRequiresAttestation: boolean | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly hasBeenFinalized: boolean | null;
  readonly id: string;
  readonly isPendingFinalization: boolean | null;
  readonly latestStudyEvent: {
    readonly id?: string;
    readonly type: string;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly viewerCanFinalize: boolean | null;
  } | null;
  readonly patient: {
    readonly medicalRecordNumber: string | null;
  } | null;
  readonly transcribedBy: {
    readonly __typename: "UserProfile";
    readonly id: string;
  } | null;
  readonly viewerCanFinalize: boolean | null;
  readonly worksheets: ReadonlyArray<{
    readonly __typename: "Worksheet";
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFilters_study" | "studyAuthors_study" | "useRefreshStudyDialog_study">;
  readonly " $fragmentType": "useStudyFinalization_study";
};
export type useStudyFinalization_study$key = {
  readonly " $data"?: useStudyFinalization_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"useStudyFinalization_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useStudyFinalization_study"
};

(node as any).hash = "9f65e461f2da5fb61cd3a8a13934c35e";

export default node;
